import Muicard from '../cards/muiCard'
import s from './services.module.css'
import PowerSupplyImg from './image/services/MRw-b_04.jpg'
import DesignImg from './image/services/proj_02.jpg'
import ELECTRICINSTALLATIONWORKImg from './image/services/image011.jpg'
import ElectroLaboratoryImg from './image/services/el_lab_03.jpg'
import PowerServiceImg from './image/services/obsl_electro.jpg'
import Typography from '@mui/material/Typography/Typography'
import {Animated} from "react-animated-css";

export const postItems = [
    {
        title: "ЭЛЕКТРОСНАБЖЕНИЕ “ПОД КЛЮЧ”",
        description: "ООО «Элэнерго» предлагает услугу Электроснабжение “под ключ”, которая позволит Вам осуществить все необходимые действия по технологическому присоединению к энергоснабжающим устройствам быстро и качественно.",
        typographyTop: [
            {
                text: ["ООО «Элэнерго» предлагает услугу Электроснабжение “под ключ”, которая позволит Вам осуществить все необходимые действия по технологическому присоединению к энергоснабжающим устройствам быстро и качественно."],
                style: {},
                tag: 'p'
            },
            {
                text: ["Энергоснабжение «под ключ» включает:"],
                style: {fontWeight: "700px"},
                tag: "h6"
            },
            {
                text: ["1. Выбор сетевой организации к сетям которой оптимально произвести подключение, подача заявки на технологическое присоединение;",
                    "2. Получение договора о технологическом присоединении и технических условий;",
                    "3. Проектирование любой сложности, в том числе:"
                ],
                style: {fontWeight: "700px"},
                tag: "li"
            },
            {
                text: [
                    " • сетей внутреннего электроснабжения 0,4 кВ, внутриплощадочных сетей;",
                    " • внешнего электроснабжения: кабельных линий (КЛ); воздушных линий (ВЛ, ВЛЗ, ВЛИ); трансформаторных подстанций (КТП, ТП, РТП, РП) – 6(10) кВ;"    
                ],
                style: {fontWeight: "700px"},
                tag: "li"
            }
        ],
        typographyBottom: [
            {
                text: [
                    "1. Согласование проектных работ в необходимых инстанциях, с собственниками земель, органами власти, Ростехнадзором;",
                    "2. Согласование проекта с сетевой организацией;",
                    "3. Проведение строительно-монтажных работ и электро-испытаний с выдачей протоколов, сдача объекта в эксплуатацию.",
                ],
                style: {},
                tag: 'li'
            },
            {
                text: [],
                style: {},
                tag: ''
            }
        ],
        image: PowerSupplyImg,
        url: '/PowerSupply'
    },
    {
        title: "ПРОЕКТИРОВАНИЕ",
        description: "Компания Элэнерго разрабатывает проекты внешнего и внутреннего электроснабжения. Качественно составленный проект позволит Вам сэкономить денежные средства на стадии строительства и в процессе эксплуатации объекта за счёт выбора оптимального технического решения.",
        typographyTop: [
            {
                text: ["Компания «Элэнерго» разрабатывает проекты электроснабжения следующих объектов:"],
                style: {fontWeight: "700"},
                tag: 'h6'
            },
            {
                text: [
                    "• Офисных помещений любой площади;",
                    "• Административных зданий;",
                    "• Бизнес-центров;",
                    "• Индидуального жилого строительства;",
                    "• Магазинов и складов;",
                    "• Производственных помещений (в т.ч. взрывоопасные);",
                    "• Кабельных Линий (в т. ч. с постановкой на сервитут) 0,4 – 6(10) кВ;",
                    "• Воздушные Линии 0,4 – 6(10) кВ;",
                    "• Трансформаторных Подстанций;",
                    "• Распределительных Пунктов.",
                ],
                style: {},
                tag: 'li'
            }
            
        ],
        typographyBottom: [
            {
                text: ['Качественно составленный проект позволит Вам сэкономить денежные средства на стадии строительства и в процессе эксплуатации объекта за счёт выбора оптимального технического решения.'],
                style: {},
                tag: 'p'
            },
            {
                text: ["При подготовке проекта мы получаем от Вас:"],
                style: {fontWeight: "700"},
                tag: 'h6'
            },
            {
                text: [
                    "• Техническое задание;",
                    "• Разрешение на присоединение мощности."
                ],
                style: {},
                tag: 'li' 
            },
            {
                text:["В соответствии с выполненным проектом, мы осуществляем авторский надзор.Готовы пройти экспертизу любого уровня (Коммерческую или Государственную).Возможна постановка вашего объекта на сервитут с прохождением всех необходимых для этого инстанций."],
                style: {},
                tag: 'li'
            }
        ],
        image: DesignImg,
        url: '/Design'
    },
    {
        title: "ЭЛЕКТРОМОНТАЖНЫЕ РАБОТЫ",
        description: "Качественный монтаж снижает стоимость дальнейшей эксплуатации, а также аварийность объекта. Организуем и выполним электромонтаж на объекте под ключ с помощью команды специалистов с разработкой проекта электроснабжения и последующим введением в эксплуатацию до получения разрешительной документации. ",
        typographyTop: [
            {
                text: ["Основным видом деятельности ООО «Элэнерго» являются электромонтажные работы в электроустановках напряжением от 0,4 до 10 кВ"],
                style: {},
                tag: 'h6'
            },
            {
                text: ["Внутренние электрические сети:"],
                style: {fontWeight: "700"},
                tag: 'p'
            },
            {
                text: [
                    " • Монтаж внутренних электрических сетей помещений разного назначения (Офисные и административные здания, бизнес-центры и торговые центры, магазины и склады, производственные помещения, МКД и др.);",
                    " • Монтаж электропроводки в жилых помещениях;",
                    " • Монтаж ВРУ и ГРЩ;",
                    " • Монтаж щитов АВР и автоматики;",
                    " • Монтаж ЩС, ЩО, ЩУ и др.",
                ],
                style: {},
                tag: 'li'
            }
        ],
        typographyBottom: [
            {
                text: ["Наружные электрические сети:",],
                style: {fontWeight: "700"},
                tag: 'p'
            },
            {
                text: [
                    " • Выполнение ТУ по утвержденному проекту/однолинейной схеме;",
                    " • Монтаж силовых Кабельных Линий внутренних и внешних сетей электроснабжения;",
                    " • Монтаж РП и ТП 6(10) кВ;",
                    " • Пуско-Наладочные работы в сетях 0,4 – 6(10) кВ;",
                    " • Приемо-сдаточные и эксплуатационные испытания в сетях 0,4 – 6 (10) кВ.",
                    " • Качественный монтаж снижает стоимость дальнейшей эксплуатации, а также аварийность объекта. Работы производятся на основании проекта, технического задания и/или технических условий.",
                    " • Организуем и выполним электромонтаж на объекте под ключ с помощью команды специалистов с разработкой проекта электроснабжения и последующим введением в эксплуатацию до получения разрешительной документации."
                ],
                style: {},
                tag: 'li'
            },
                 ],
        image: ELECTRICINSTALLATIONWORKImg,
        url: '/ELECTRICINSTALLATIONWORK'
    },
    {
        title: "ЭЛЕКТРОЛАБОРАТОРИЯ",
        description: "Для предотвращения нештатных ситуаций и обеспечения бесперебойной работы электроустановок и оборудования необходимо периодически проводить обследования и измерение основных требуемых параметров. Лаборатория укомплектована переносными приборами и имеет право на проведение измерений, а также эксплуатационных и приемо-сдаточных испытаний электрооборудования до 10 кВ.",
        typographyTop: [ 
            {
                text: ["Для предотвращения нештатных ситуаций и обеспечения бесперебойной работы электроустановок и оборудования необходимо периодически проводить обследования и измерение основных требуемых параметров.",],
                style: {},
                tag: 'p'
            },
            {
                text: ["Лаборатория укомплектована переносными приборами и имеет право на проведение измерений, а также эксплуатационных и приемо-сдаточных испытаний электрооборудования до 10 кВ.",],
                style: {},
                tag: 'p'
            },
        ],
        typographyBottom: [
            {
                text: ["Перечень разрешенных видов испытаний и измерений:",],
                style: {fontWeight: "700"},
                tag: 'h6'
            },
            {
                text: [
                    "1.Визуальный осмотр и тепловизионный контроль состояния электрооборудования;",
                    "2.Измерения сопротивления изоляции электрооборудования;",
                    "3.Проверка на непрерывность заземляющих и защитных проводников, включая проводники основной и дополнительной системы уравнивания потенциалов в электроустановках напряжением до 1 кВ",
                    "4.Проверка автоматических выключателей и тепловых реле;",
                    "5.Проверка срабатывания автоматического отключения питания;",
                    "6.Испытание выключателей автоматических, управляемых дифференциальным током (УЗО);",
                    "7.Измерение сопротивления заземлителей и заземляющих устройств;",
                    "8. Испытание устройств АВР",
                    "9.Проверка реле магнитного действия РТ и РН;",
                    "10.Испытания силовых трансформаторов и автотрансформаторов напряжением до 10 кВ, в том числе испытание трансформаторного масла на пробой; ",
                    "11.Испытание повышенным напряжением распределительного устройства;",
                    "12.Испытания силовых кабельных линий напряжением до 10 кВ;",
                    "13.Испытания масляных, вакуумных и элегазовых выключателей напряжением до 10 кВ;",
                    "14.Испытания разъединителей, отделителей и короткозамыкателей в электроустановках напряжением до 10 кВ;",
                    "15.Испытания измерительных трансформаторов тока и напряжения;",
                    "16.Испытания фарфоровых подвесных и опорных изоляторов до 10 кВ;",
                    "17.Испытания вентильных разрядников и ограничителей перенапряжения;",
                    '18. Испытания электродвигателей переменного тока напряжением до 10 кВ.'
            ],
                style: {},
                tag: 'li'
            },
       
        ],
        image: ElectroLaboratoryImg,
        url: '/ElectroLaboratory'
    },
    {
        title: "ОБСЛУЖИВАНИЕ ЭЛЕКТРОХОЗЯЙСТВ",
        description: "Обслуживание электрохозяйства или электроустановки в настоящее время является неотъемлемой составляющей в эксплуатации зданий и сооружений или отдельных их помещений и электрооборудования.",
        typographyTop: [
            {
                text: ["Обслуживание электрохозяйства или электроустановки в настоящее время является неотъемлемой составляющей в эксплуатации зданий и сооружений или отдельных их помещений и электрооборудования.",],
                style: {},
                tag: 'p',
            },
            {
                text: ['Заключая договор с нашей компанией Вы получаете гарантированное посещение специалистами Ваших объектов согласно графика, проведение технического обслуживания, устранения выявленных дефектов и ремонтов, а при необходимости или аварийных ситуациях срочный выезд электрика, в т.ч. в ночное и праздничное время (круглосуточно).'],
                style: {},
                tag: 'p',
            }
        ],
        typographyBottom: [
            {
                text: ["Кто может проводить техническое обслуживание электроустановки?"],
                style: {fontWeight: "700"},
                tag: 'h6',
            },
            {
                text: ["В электроустановках до 1000В оперативно-ремонтный персонал, обслуживающий электроустановку, должен соответствовать специальным требованиям контролирующих органов. Административно-технический персонал организации (ответственный за электрохозяйство, руководитель и т.д.) не может проводить работы по обслуживанию. Для самостоятельного обслуживания электроустановки организация должна иметь службу эксплуатации и аттестованный оперативно-ремонтный персонал. В случае отсутствия собственной службы эксплуатации электроустановок, организация обязана заключить договор на техническое обслуживание электроустановки со специализированной обслуживающей организацией.",],
                style: {},
                tag: 'p',
            },
            {
                text: ['Почему Договор на техническое обслуживание с нами выгоден для Заказчика?'],
                style: {fontWeight: "700"},
                tag: 'h6',
            },
            {
                text: [ 
                " • Отсутствие затрат на содержание специализированного персонала (заработная плата, отпускные, больничные листы и т.п.);",
                " • Отсутствие затрат на обучение сотрудников и повышение их квалификации;",
                " • Отсутствие затрат на содержание офисных площадей для подразделения, занимающегося обслуживанием электросетей;",
                " • Отсутствие затрат на закупку и последующее содержание в надлежащем состоянии инструментов и принадлежностей для проведения ТО и ремонта электроустановки, в т.ч. – электроизмерительная лаборатория;",
                " • Отсутствие затрат на содержания собственного автопарка (и штата водителей) для доставки специалистов ремонтного подразделения к месту проведения работ;",
                " • Отсутствие необходимости заключения дополнительных договоров, например – Договора поверки приборов и принадлежностей и т.п.;",
                " • Отсутствие затрат на закупку, хранение, периодическую проверку электроизмерительного оборудования.",
    ],
                style: {},
                tag: 'li',
            },
        ],
        image: PowerServiceImg,
        url: '/PowerService'
    },
]


function Services() {
    const CardList = postItems.map((card) => {
        return (<Muicard cardInfo={card} key={card.title} />)
    })
    return (
        <>
        <div id="TitlePage"></div>
        <Animated animationIn="fadeInUpBig" animationOut="fadeOut" isVisible={true}>
            <Typography variant="h3" component="div" sx={{ textAlign: 'center', display: {xs: "none" , md: 'block'}, letterSpacing: '.3rem' }}>
             Наши услуги
            </Typography>
            <Typography variant="h4" component="div" sx={{margin:'150px 60px 0', textAlign: 'center', display: {xs: "flex" , md: 'none'}, letterSpacing: '.3rem' }}>
             Наши услуги
            </Typography>
            <div className={s.cardList}>
                {CardList}
            </div>
            </Animated>
        </>
    )
}

export default Services;